// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-benefits-page-js": () => import("./../../../src/templates/benefits-page.js" /* webpackChunkName: "component---src-templates-benefits-page-js" */),
  "component---src-templates-blog-blog-category-list-js": () => import("./../../../src/templates/blog/blog-category-list.js" /* webpackChunkName: "component---src-templates-blog-blog-category-list-js" */),
  "component---src-templates-blog-blog-list-js": () => import("./../../../src/templates/blog/blog-list.js" /* webpackChunkName: "component---src-templates-blog-blog-list-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/front-page.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-legal-doc-js": () => import("./../../../src/templates/legalDoc.js" /* webpackChunkName: "component---src-templates-legal-doc-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

